"use client"

import { Link, usePathname, Locale } from "@/lib/i18n"

export function I18nMenuLink({ className, locale, children }: {
    className?: string
    locale: Locale
    children: React.ReactNode
}) {
    const pathname = usePathname()

    return (
        <Link href={pathname} locale={locale} className={className}>
            {children}
        </Link>
    )
}