import { notFound } from 'next/navigation'
import { getRequestConfig } from 'next-intl/server'
import { createSharedPathnamesNavigation } from 'next-intl/navigation'

export const locales = [
    'en-US'
    //'de-DE'
] as const
export type Locale = (typeof locales)[number]

export const defaultLocale = 'en-US' as const

export const localePrefix = 'always' as const

export const localeNames = {
    'en-US': {
        name: 'English',
        country: 'United States'
    },
    'de-DE': {
        name: 'Deutsch',
        country: 'Deutschland'
    }
} as const

export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation({ locales, localePrefix })

// TODO: remove "declarations": false from tsconfig.json and fix the following error
export default getRequestConfig(async ({ locale }) => {
    // Validate that the incoming `locale` parameter is valid
    if (!locales.includes(locale as any)) notFound()

    return {
        messages: (
            await (locale === 'en-US'
                ? // When using Turbopack, this will enable HMR for `en`
                  import('../locales/en-US.json')
                : import(`../locales/${locale}.json`))
        ).default
    }
})
