'use client'

import { createSupabaseBrowserClient } from '@/lib/supabase/client'
import {
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator
} from '@kotao/ui/dropdown-menu'

export function NavbarUserMenuContent({ first_name, last_name }: { first_name: string; last_name: string }) {
    async function handleLogout() {
        const supabase = createSupabaseBrowserClient()

        await supabase.auth.signOut()

        window.location.reload()
    }

    return (
        <DropdownMenuContent align="end">
            <DropdownMenuLabel>
                {first_name} {last_name}
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Settings</DropdownMenuItem>
            <DropdownMenuItem>Support</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleLogout}>Logout</DropdownMenuItem>
        </DropdownMenuContent>
    )
}
