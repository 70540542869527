'use client'

import React, { useEffect, useState } from 'react'

export function NavbarWrapper({ children }: { children: React.ReactNode }) {
    const [scrollY, setScrollY] = useState(0)

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY)
        }

        // just trigger this so that the initial state
        // is updated as soon as the component is mounted
        // related: https://stackoverflow.com/a/63408216
        handleScroll()

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <nav
            className={`sticky left-0 right-0 top-0 z-30 w-full border-b transition ${scrollY <= 0 ? 'border-transparent bg-transparent' : 'bg-background shadow-xs'}`}
        >
            {children}
        </nav>
    )
}
