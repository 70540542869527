'use client'

import { Moon, Sun } from 'lucide-react'
import { useTheme } from 'next-themes'

import { Button, ButtonProps } from './button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuTrigger
} from './dropdown-menu'

export interface ModeToggleProps {
    buttonProps?: ButtonProps
    translations: {
        toggleTheme: string
        light: string
        dark: string
        system: string
    }
}

export function ModeToggle({ translations, buttonProps }: ModeToggleProps) {
    const { setTheme, theme } = useTheme()

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon" {...buttonProps}>
                    <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
                    <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
                    <span className="sr-only">{translations.toggleTheme}</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <DropdownMenuRadioGroup value={theme} onValueChange={setTheme}>
                    <DropdownMenuRadioItem value="light">{translations.light}</DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="dark">{translations.dark}</DropdownMenuRadioItem>
                    <DropdownMenuRadioItem value="system">{translations.system}</DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
