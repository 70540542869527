import(/* webpackMode: "eager", webpackExports: ["I18nMenuLink"] */ "/vercel/path0/apps/manager/components/i18n-menu-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarUserMenuContent"] */ "/vercel/path0/apps/manager/components/navbar-user-menu-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarWrapper"] */ "/vercel/path0/apps/manager/components/navbar-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.15.3_next@14.2.3_react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.15.3_next@14.2.3_react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/vercel/path0/packages/ui/components/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/components/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModeToggle"] */ "/vercel/path0/packages/ui/components/mode-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationMenu","NavigationMenuList","NavigationMenuItem","NavigationMenuTrigger","NavigationMenuContent","NavigationMenuLink","NavigationLink"] */ "/vercel/path0/packages/ui/components/navigation-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsList","TabsTrigger","TabsContent"] */ "/vercel/path0/packages/ui/components/tabs.tsx");
